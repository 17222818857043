import { Box, TableCell } from '@mui/material';
import { FC } from 'react';
import { CELL_WIDTH, tableStyles } from './video-segment.styles';
import { TimeDisplay } from './video-segment.types';

interface Props {
  firstCellOffset: number;
  timeDisplay: TimeDisplay[];
}

export const VideoSegmentTimeLine: FC<Props> = ({ firstCellOffset, timeDisplay }) => {
  const { classes } = tableStyles();

  return <>
    {timeDisplay.map((time, index) => {
      const cellWidth = index === 0 ? CELL_WIDTH - firstCellOffset : CELL_WIDTH;

      return (
        <TableCell
          key={index}
          className={classes.tableCell}
          sx={{ maxWidth: cellWidth, minWidth: cellWidth }}
        >
          <Box sx={{ borderLeft: (theme) => `1px solid ${theme.palette.grey[500]}`, height: time.tickHeight }} />
          {time.display}
        </TableCell>
      );
    })}
  </>;
};
