/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import { getThingFeatureCombinations, Thing, ThingType } from '@eagle/core-data-types';
import { CacheDataTypes, ErrorMessage, MiddleSpinner, testid, useAuthenticated, useFetchAllCache, usePromise, useSmallScreen } from '@eagle/react-common';
import { Button } from '@mui/material';
import { debounce } from 'lodash';
import { useSnackbar } from 'notistack';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useAvailableSegments } from '../video-segment/video-content-util';

interface Props {
  bannerOpen: (data: string) => void;
  dateRange: {
    endDate: Date;
    startDate: Date;
  };
  thing: Thing;
}

export const RequestAvailableMedia: FC<Props> = ({ dateRange, bannerOpen, thing: thingData }) => {
  const smallScreen = useSmallScreen();
  const { t } = useTranslation(['track']);
  const { axios } = useAuthenticated();
  const { enqueueSnackbar } = useSnackbar();

  const thingTypeCache = useFetchAllCache(CacheDataTypes.THING_TYPE);

  const [thingType, thingTypeError, thingTypeState] = usePromise(() => {
    return thingTypeCache.one<ThingType>(thingData?.thingTypeId);
  }, [thingTypeCache, thingData]);

  const requestAvailable = useCallback(
    debounce(async (data: { start: Date; finish: Date }, thingType?: ThingType): Promise<void> => {
      try {
        if (thingData && thingType) {
          const cameraFeature = thingType.features.find((feature) => feature.featureId === 'camera');
          const cameraFeatures = cameraFeature ? getThingFeatureCombinations(cameraFeature) : [];
          await Promise.all(cameraFeatures.map(async (feature) => {
            await useAvailableSegments({ ...data, feature }, thingData?._id, axios);
          }));
          bannerOpen(t('track:page.camera-content.hint.availability-requested'));
        }
      }
      catch (e) {
        enqueueSnackbar(<ErrorMessage error={e as Error} />, { variant: 'error' });
      }
    }, 500)
    , []
  );

  if (thingTypeState === 'pending') return <MiddleSpinner />;
  if (thingTypeError) {
    enqueueSnackbar(<ErrorMessage error={thingTypeError} />, { variant: 'error' });
    return <></>;
  }

  return (
    <Button variant="contained" size="small" data-testid={testid`action-button-${t('track:page.camera-content.request-media.action')}`} onClick={() => requestAvailable({
      start: dateRange.startDate,
      finish: dateRange.endDate,
    }, thingType)}>
      {smallScreen ? t('track:page.camera-content.request-media-short.action') : t('track:page.camera-content.request-media.action')}
    </Button>
  );
};
