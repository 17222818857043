/* eslint-disable react-hooks/exhaustive-deps */
import { getThingFeatureCombinations, ThingType } from '@eagle/core-data-types';
import { VideoSegmentStatus } from '@eagle/data-function-types';
import { FilterToolbar, Undefinable } from '@eagle/react-common';
import { Button, Checkbox, Drawer, FormControlLabel, Stack } from '@mui/material';
import { Box, styled } from '@mui/system';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterType, useVideoFilters } from './camera-content';

interface Props {
  open: boolean;
  onClose: () => void;
  thingType: Undefinable<ThingType>;
}

const StyledDrawer = styled(Drawer)(() => ({
  '& .MuiPaper-root': {
    width: 'inherit',
  },
}));

interface ChipsProps {
  handleAllClicked: (checked: boolean) => void;
  onChipChange: (checked: boolean, status: string) => void;
  options: string[];
  parentLabel: string;
  selected: string[];
  translation: (status: string) => string;
}

const ChipsWithChildren: FC<ChipsProps> = ({ handleAllClicked, onChipChange, options, parentLabel, selected, translation }) => {
  const allChecked = selected.length === options.length;
  const indeterminate = selected.length > 0 && !allChecked;

  return (
    <Box>
      <FormControlLabel
        control={<Checkbox
          checked={allChecked}
          indeterminate={indeterminate}
          onChange={() => handleAllClicked(allChecked)}
        />}
        label={parentLabel}
      />
      <Stack sx={{ ml: 3 }}>
        {
          options.map((status, i) => (
            <FormControlLabel
              key={`${status}-${i}`}
              checked={selected.includes(status)}
              control={<Checkbox />}
              label={translation(status)}
              onChange={(_event, checked) => onChipChange(checked, status)}
            />
          ))
        }
      </Stack>
    </Box>
  );
};

export const MobileFilterDrawer: FC<Props> = (props) => {
  const { t } = useTranslation(['common', 'track']);
  const { onClose } = props;
  const thingType = props.thingType;

  const cameraFeature = thingType?.features.find((feature) => feature.featureId === 'camera');
  const thingCameraFeatures = cameraFeature ? getThingFeatureCombinations(cameraFeature) : [];

  const { filters, setFilters } = useVideoFilters();

  const statusFilters = filters.filter((filter) => filter.status).map((filter) => filter.status);
  const cameraFilters = filters.filter((filter) => filter.feature).map((filter) => filter.feature);

  const resetFeaturesCheckbox = (): string[] => filters.length ? statusFilters : Object.values(VideoSegmentStatus);
  const resetCamerasCheckbox = (): string[] => filters.length ? cameraFilters : thingCameraFeatures;

  const [featuresCheckbox, setFeaturesCheckbox] = useState(resetFeaturesCheckbox());
  const [camerasCheckbox, setCamerasCheckbox] = useState(resetCamerasCheckbox());

  useEffect(() => {
    setCamerasCheckbox(resetCamerasCheckbox());
  }, [cameraFeature]);

  const handleAllFeaturesClicked = (checked: boolean): void => {
    if (checked) return setFeaturesCheckbox([]);
    setFeaturesCheckbox(Object.values(VideoSegmentStatus));
  };

  const handleAllCamerasClicked = (checked: boolean): void => {
    if (checked) return setCamerasCheckbox([]);
    setCamerasCheckbox(thingCameraFeatures);
  };

  const onFiltersChipChange = (checked: boolean, status: string): void => {
    if (!status) return;
    if (checked) return setFeaturesCheckbox((prev) => [...prev, status]);
    setFeaturesCheckbox((prev) => prev.filter((stateStatus) => status !== stateStatus));
  };

  const onCamerasChipChange = (checked: boolean, status: string): void => {
    if (!status) return;
    if (checked) return setCamerasCheckbox((prev) => [...prev, status]);
    setCamerasCheckbox((prev) => prev.filter((stateStatus) => status !== stateStatus));
  };

  const onCloseInternal = (): void => {
    setFeaturesCheckbox(resetFeaturesCheckbox());
    setCamerasCheckbox(resetCamerasCheckbox());
    onClose();
  };

  const onUpdate = (): void => {
    const objectArray = [...featuresCheckbox.map((status) => ({ status })), ...camerasCheckbox.map((feature) => ({ feature }))];
    setFilters(objectArray as unknown as Record<FilterType, string>[]);
    onClose();
  };

  return (
    <StyledDrawer
      anchor="left"
      sx={{
        width: props.open ? '100vw' : 0,
        zIndex: 1300,
      }}
      {...props}
    >
      <FilterToolbar onCloseClicked={onCloseInternal} />
      <Stack spacing={2} sx={{ px: 2.5 }}>
        {thingCameraFeatures.length > 0
          && <ChipsWithChildren
            handleAllClicked={handleAllCamerasClicked}
            onChipChange={onCamerasChipChange}
            options={thingCameraFeatures}
            parentLabel={t('common:features.camera')}
            selected={camerasCheckbox}
            translation={(camera: string) => t(`common:features.camera-type.${camera}`)}
          />
        }
        <ChipsWithChildren
          handleAllClicked={handleAllFeaturesClicked}
          onChipChange={onFiltersChipChange}
          options={Object.values(VideoSegmentStatus)}
          parentLabel={t('track:page.camera-content.labels.status')}
          selected={featuresCheckbox}
          translation={(status: string) => t(`track:page.camera-content.status.labels.${status}`)}
        />
        <Stack direction="row" spacing={2} sx={{ justifyContent: 'flex-end', alignItems: 'center', pb: 2 }}>
          <Button onClick={onCloseInternal} sx={{ m: '.5rem' }}>{t('common:common.action.cancel')}</Button>
          <Button onClick={onUpdate} sx={{ m: '.5rem' }} variant="contained">{t('common:common.action.update')}</Button>
        </Stack>
      </Stack>
    </StyledDrawer>
  );
};
