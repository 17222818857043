/* eslint-disable react-hooks/exhaustive-deps */
import { VideoSegmentStatus } from '@eagle/data-function-types';
import { ButtonBase, getFormattedTimeWithSeconds, useAuthenticated, VideoCellChip, VideoCellIcon } from '@eagle/react-common';
import { Grid, Stack, Tooltip, Typography } from '@mui/material';
import cx from 'classnames';
import { debounce } from 'lodash';
import { DateTime, Duration } from 'luxon';
import { FocusEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { scrollIntoView } from 'seamless-scroll-polyfill';
import { requestSegmentUpload } from './video-content-util';
import { DEFAULT_PIXEL_LENGTH } from './video-segment-row';
import { tableStyles } from './video-segment.styles';
import { RequestUpload, VideoCell, VideoCellType } from './video-segment.types';

const EXPANDED_SEGMENT_MIN_SIZE = 250;

interface Props {
  bannerOpen: (data: string) => void;
  handleFocus: (event: FocusEvent) => void;
  mobile?: boolean;
  modalOpen: (url?: string, timeStart?: Date, feature?: string) => void;
  thingId: string;
  videoCell: VideoCell;
}

const VideoSegmentCell: React.FC<Props> = ({ bannerOpen, handleFocus, mobile, modalOpen, thingId, videoCell }) => {
  const [requestCell, setRequestCell] = useState(false);
  const [visited, setVisited] = useState(false);
  const [videoCellStatus, setVideoCellStatus] = useState(videoCell.status);
  const { t } = useTranslation(['track']);
  const { axios } = useAuthenticated();
  const { classes } = tableStyles();
  const videoRequest = useCallback(
    debounce(async (data: RequestUpload): Promise<void> => {
      try {
        setRequestCell(true);
        setVideoCellStatus(VideoSegmentStatus.REQUESTED);
        await requestSegmentUpload(data, thingId, axios);
        bannerOpen(t('track:page.camera-content.request-media.hint.success'));
      }
      catch (e) {
        setRequestCell(false);
      }
    }, 500)
    , []
  );

  const handleClick = (data: RequestUpload): void => {
    switch (data.status) {
      case VideoSegmentStatus.UPLOAD_COMPLETE:
        modalOpen(videoCell?.url?.toString(), videoCell.timeStart, videoCell.feature);
        break;
      case VideoSegmentStatus.REQUEST_FAILED:
        void videoRequest(data);
        break;
      case VideoSegmentStatus.UPLOAD_FAILED:
        void videoRequest(data);
        break;
      case VideoSegmentStatus.RECORDED:
        void videoRequest(data);
        break;
      case VideoSegmentStatus.NOT_AVAILABLE:
        console.log('Error');               // TODO TP-xxxx : Present Error Overlay
        break;
      default:
        break;
    }
  };

  const leftCornerRadius = (videoCell.type === VideoCellType.BEFORE_START) ? '0' : '0.25rem';
  const rightCornerRadius = (videoCell.type === VideoCellType.AFTER_END) ? '0' : '0.25rem';

  const timeStart = getFormattedTimeWithSeconds(DateTime.fromJSDate(videoCell.timeStart));
  const timeFinish = getFormattedTimeWithSeconds(DateTime.fromJSDate(videoCell.timeFinish));
  const dateRangeText = `${timeStart} - ${timeFinish}`;
  const tooltipText = <Stack sx={{ textAlign: 'center' }}>
    <Typography variant="caption" sx={{ fontSize: { xs: '0.75rem', sm: 'inherit' } }}>{dateRangeText}</Typography>
    <Typography variant="caption" sx={{ fontSize: { xs: '0.625rem', sm: 'inherit' } }}>{t(`track:page.camera-content.status.labels.${videoCellStatus}`)}</Typography>
  </Stack>;

  const duration = Duration.fromMillis(videoCell.timeLength).shiftTo('minutes', 'seconds');
  const durationMinutes = duration.minutes ? `${t('track:page.camera-content.labels.duration-minute', { count: duration.minutes, minute: duration.minutes })} ` : '';
  const durationSecondsRounded = Math.round(duration.seconds);
  const durationStr = `${durationMinutes}${t('track:page.camera-content.labels.duration-second', { count: durationSecondsRounded, second: durationSecondsRounded })}`;

  const textColor = (videoCellStatus === VideoSegmentStatus.UPLOAD_STARTED || videoCellStatus === VideoSegmentStatus.REQUESTED || videoCellStatus === VideoSegmentStatus.REQUEST_ACKED)
    ? 'rgba(0, 0, 0, 0.87)'
    : 'white';

  const getClassForVideoCellStatus = (): string => {
    switch (videoCellStatus) {
      case VideoSegmentStatus.RECORDED:
        return !requestCell ? classes.videoRecorded : '';
      case VideoSegmentStatus.REQUESTED:
      case VideoSegmentStatus.REQUEST_ACKED:
        return classes.videoRequested;
      case VideoSegmentStatus.UPLOAD_COMPLETE:
        return visited ? classes.videoUploadCompleteVisited : classes.videoUploadComplete;
      case VideoSegmentStatus.UPLOAD_FAILED:
      case VideoSegmentStatus.REQUEST_FAILED:
        return !requestCell ? classes.videoUploadFailed : '';
      case VideoSegmentStatus.UPLOAD_STARTED:
        return classes.videoUploadStarted;
      case VideoSegmentStatus.NOT_AVAILABLE:
        return classes.videoNotAvailable;
      case VideoSegmentStatus.NO_LONGER_AVAILABLE:
        return classes.videoNoLongerAvailable;
    }
  };

  const buttonClasses = cx(
    classes.videoCell,
    { [classes.videoStartAvailableRequest]: requestCell },
    getClassForVideoCellStatus(),
  );

  return (
    <Grid item>
      <Tooltip
        arrow
        enterDelay={200}
        followCursor
        placement="top"
        title={tooltipText}
      >
        <Stack>
          <ButtonBase
            className={buttonClasses}
            disableRipple
            onClick={() => {
              setVisited(true);
              handleClick({
                start: videoCell.timeStart,
                finish: videoCell.timeFinish,
                feature: videoCell.feature,
                featureTypeId: videoCell.featureTypeId,
                status: videoCell.status,
              });
            }}
            onFocusVisible={(event: FocusEvent) => {
              scrollIntoView(event.target as Element, { behavior: 'smooth', inline: 'center' });
              handleFocus(event);
            }}
            sx={{
              borderRadius: `${leftCornerRadius} ${rightCornerRadius} ${rightCornerRadius} ${leftCornerRadius}`,
              marginBottom: '8px',
              width: mobile ? '100%' : `${videoCell?.pixelLength ?? DEFAULT_PIXEL_LENGTH}px`,
            }}>
            {videoCell?.pixelLength && videoCell.pixelLength < EXPANDED_SEGMENT_MIN_SIZE
              ? <VideoCellIcon color={textColor} videoSegmentStatus={videoCellStatus} />
              : <Stack className={classes.videoCellExpanded} direction="row">
                <Stack sx={{ color: textColor, textAlign: 'left' }}>
                  <Typography variant="subtitle2" sx={{ lineHeight: '1.25', fontSize: mobile ? '0.75rem' : '0.9rem' }}>{dateRangeText}</Typography>
                  <Typography variant="caption" sx={{ lineHeight: '1', fontSize: mobile ? '0.625' : '0.75rem', opacity: '0.75' }}>{mobile ? t(`common:features.camera-type.${videoCell.feature}`) : durationStr}</Typography>
                </Stack>
                <VideoCellChip
                  fontSize="0.625rem"
                  label={<Typography variant="caption" sx={{ fontSize: mobile ? '0.625rem' : 'inherit' }}>{t(`track:page.camera-content.status.labels.${videoCellStatus}`)}</Typography>}
                  videoCellStatus={videoCellStatus}
                />
              </Stack>
            }
          </ButtonBase>
        </Stack>
      </Tooltip>
    </Grid>
  );
};

export default VideoSegmentCell;
