/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid } from '@mui/material';
import { FC, Fragment, useEffect } from 'react';
import { FeatureRow } from './video-segment-view';

interface Props {
  features: FeatureRow[];
  handleGridLoaded: () => void;
}

export const LayoutMobile: FC<Props> = ({
  features,
  handleGridLoaded,
}) => {
  useEffect(handleGridLoaded, [features]);

  return (
    <Box flexGrow={1} overflow="auto" sx={{ '&': { height: '100%' }, height: '100%' }}>
      <Grid container direction="column" sx={{ height: '100%' }}>
        {features.map(({ renderRow }, i) => <Fragment key={i}>{renderRow}</Fragment>)}
      </Grid>
    </Box>
  );
};
