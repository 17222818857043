import { makeStyles } from '@eagle/react-common';

export const CELL_WIDTH = 60;
export const FEATURE_COLUMN_WIDTH = 100;
export const TIMELINE_BAR_WIDTH = 1;
export const TABLE_BORDER_WIDTH = 1;

export const tableStyles = makeStyles()((theme) => ({
  alertCellBackground: {
    borderRadius: '100%',
    height: '1em',
    position: 'absolute',
    width: '1em',
  },
  emptyCell: {
    height: '40px',
    marginBottom: theme.spacing(0.5),
  },
  table: {
    borderCollapse: 'separate',
    contain: 'paint',
    width: 'auto',
  },
  tableCell: {
    backgroundColor: theme.palette.grey[100],
    padding: 0,
    overflow: 'visible',
    whiteSpace: 'nowrap',
  },
  tableFirstColumn: {
    backgroundColor: theme.palette.background.paper,
    borderRight: theme.videoSegment.tableBorder,
    left: 0,
    maxWidth: FEATURE_COLUMN_WIDTH,
    minWidth: FEATURE_COLUMN_WIDTH,
    overflow: 'hidden',
    paddingLeft: 0,
    paddingRight: 0,
    position: 'sticky',
    textAlign: 'center',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    zIndex: 10,
  },
  videoCell: {
    boxShadow: theme.videoSegment.videoCellBoxShadow,
    flexShrink: 0,
    height: '40px',
    zIndex: 1,
    '&:focus, &:focus-visible': {
      boxShadow: theme.videoSegment.videoCellFocusedBoxShadow,
    },
  },
  videoCellExpanded: {
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '100%',
  },
  videoModal: {
    backgroundColor: 'white',
    height: '300px',
    left: '50%',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '300px',
  },
  videoNoLongerAvailable: {
    backgroundColor: theme.videoStatus.noLongerAvailable,
  },
  videoNotAvailable: {
    backgroundColor: theme.videoStatus.notAvailable,
  },
  videoRow: {
    maxHeight: '200px',
    verticalAlign: 'top',
  },
  videoRecorded: {
    backgroundColor: theme.videoStatus.recorded,
  },
  videoRequested: {
    backgroundColor: theme.videoStatus.requested,
  },
  videoStartAvailableRequest: {
    backgroundColor: theme.videoStatus.requested,
    transition: 'background-color 1000ms ease-in, border-color 1000ms ease-in',
  },
  videoUploadComplete: {
    backgroundColor: theme.videoStatus.uploadComplete,
  },
  videoUploadCompleteVisited: {
    backgroundColor: theme.videoStatus.uploadCompleteVisited,
  },
  videoUploadFailed: {
    backgroundColor: theme.videoStatus.uploadFailed,
  },
  videoUploadStarted: {
    backgroundColor: theme.videoStatus.uploadStarted,
  },
}));
