import { getFileExtension, getFormattedTimeWithSeconds, safeFilename, Undefinable } from '@eagle/react-common';
import { DateTime } from 'luxon';

export const getTickHeight = (time: DateTime): number => {
  if (time.minute % 10 === 0) return 30;
  if (time.minute % 5 === 0) return 20;
  return 10;
};

export const getDisplay = (time: DateTime): string => {
  return time.minute % 10 === 0 ? getFormattedTimeWithSeconds(time) : '';
};

export const getFileName = (display: string, url?: string, timeStart?: Date, feature?: string): Undefinable<string> => {
  if (!timeStart || !feature || !url) return;
  return safeFilename`${display}-${feature}-${timeStart}.${getFileExtension(url)}`;
};
