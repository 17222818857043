/* eslint-disable react-hooks/exhaustive-deps */
import { AlertResponse, SegmentData, Thing } from '@eagle/core-data-types';
import { DateRange, getFormattedTime, getFormattedTimeWithSeconds, useSmallScreen, VideoModal } from '@eagle/react-common';
import { TableCell, TableRow } from '@mui/material';
import { DateTime } from 'luxon';
import { FC, FocusEvent, UIEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useVideoFilters } from '../camera-content';
import { CameraDateOptions } from '../camera-content.types';
import { AlertSegmentRow } from './alert-segment-row';
import { LayoutDesktop } from './layout-desktop';
import { LayoutMobile } from './layout-mobile';
import { VideoSegmentRow } from './video-segment-row';
import { VideoSegmentRowMobile } from './video-segment-row-mobile';
import { VideoSegmentTimeLine } from './video-segment-time-line';
import { FEATURE_COLUMN_WIDTH, tableStyles, TABLE_BORDER_WIDTH, TIMELINE_BAR_WIDTH } from './video-segment.styles';
import { TimeDisplay } from './video-segment.types';
import { getDisplay, getFileName, getTickHeight } from './video-segment.utils';

export interface FeatureRow {
  featureDisplay: string;
  renderRow: JSX.Element;
}

interface Props {
  alertData: Promise<AlertResponse[]>;
  bannerOpen: (data: string) => void;
  dateRange: CameraDateOptions;
  disableAutoScroll: boolean;
  disableBack?: boolean;
  disableForward?: boolean;
  features: string[];
  handleBack?: (date: DateRange, minDateTime?: DateTime) => void;
  handleForward?: (date: DateRange, mobileLogic?: boolean) => void;
  handleGridLoaded: () => void;
  refresh: boolean;
  thing: Thing;
  videoData: {
    data: Promise<SegmentData[]>;
    feature: string;
  }[];
}

export const VideoSegmentView: FC<Props> = ({
  alertData,
  bannerOpen,
  dateRange,
  disableAutoScroll,
  disableBack,
  disableForward,
  handleBack,
  handleForward,
  handleGridLoaded,
  refresh,
  thing,
  videoData,
}) => {
  const [displayAlertRow] = useState(true);
  const [enableTooltip, setEnableTooltip] = useState(false);
  const [finishDate, setFinishDate] = useState<Date>();
  const [focusedItem, setFocusedItem] = useState<Element>();
  const [lineOffset, setLineOffset] = useState(0);
  const [modalFileName, setModalFileName] = useState<string>();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalURL, setModalURL] = useState('');
  const [rowRender, setRowRender] = useState<FeatureRow[]>([]);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [tooltipTime, setTooltipTime] = useState('');
  const smallScreen = useSmallScreen();

  const areaRef = useRef<HTMLDivElement>(null);
  const tableBody = areaRef.current?.getBoundingClientRect();
  const tableContainerRef = useRef<HTMLTableElement>(null);

  const { classes } = tableStyles();
  const { startTime, endTime, scrollOptions } = dateRange;
  const { t } = useTranslation(['common', 'track']);
  const { filters } = useVideoFilters();

  const handleModalClose = (): void => setModalOpen(false);

  const handleModalOpen = (url?: string, timeStart?: Date, feature?: string): void => {
    if (!url) return;
    setModalURL(url);
    setModalFileName(getFileName(thing.display, url, timeStart, feature));
    setModalOpen(true);
  };

  const handleLineOffset = (positionX: number): void => {
    if (positionX < 0 || !tableBody) return;

    const tableLeft = Math.floor(tableBody.left);
    const offset = Math.floor(positionX - tableLeft);
    setLineOffset(offset);
    setEnableTooltip(offset > FEATURE_COLUMN_WIDTH + TIMELINE_BAR_WIDTH && offset < tableBody.width);

    const seconds = offset - FEATURE_COLUMN_WIDTH - TABLE_BORDER_WIDTH - TIMELINE_BAR_WIDTH + scrollLeft;
    if (seconds < 0) return;

    const time = startTime.plus({ seconds });
    setTooltipTime(getFormattedTimeWithSeconds(time));
  };

  const handleFocus = (event: FocusEvent): void => setFocusedItem(event.target);

  const handleMouseLeave = (): void => setEnableTooltip(false);

  const handleScroll = (event: UIEvent): void => setScrollLeft((event.target as HTMLElement).scrollLeft);

  const updateFinishDate = useCallback((date?: Date): void => {
    if (disableAutoScroll) return setFinishDate(undefined);
    if (!date) return;
    if (endTime.toJSDate() < date) return setFinishDate(endTime.minus({ 'minutes': 1 }).toJSDate());
    setFinishDate((previous) => !previous || previous < date ? date : previous);
  }, [finishDate, disableAutoScroll]);

  const timeDisplay = useMemo((): TimeDisplay[] => {
    const timeDiff = Math.floor(Number(endTime.diff(startTime, 'minutes').minutes));
    return Array.from(new Array(timeDiff), (_value, index) => {
      const timeSegment = startTime.plus({ seconds: 60 * index - startTime.second });
      const timeValue = getFormattedTime(timeSegment);
      const isFirstSegmentWithSecond = index === 0 && startTime.second;

      return {
        display: isFirstSegmentWithSecond ? '' : getDisplay(timeSegment),
        tickHeight: isFirstSegmentWithSecond ? 0 : getTickHeight(timeSegment),
        timeValue,
      };
    });
  }, [endTime, startTime]);

  const tableHeadRow = useMemo(() => (
    <TableRow data-chromatic="ignore" sx={{ backgroundColor: (theme) => theme.palette.grey[100], verticalAlign: 'top' }}>
      <TableCell className={classes.tableFirstColumn}>
        {getFormattedTimeWithSeconds(startTime)}
      </TableCell>
      <VideoSegmentTimeLine
        firstCellOffset={startTime.second}
        timeDisplay={timeDisplay}
      />
    </TableRow>
  ), [timeDisplay, startTime]);

  const alertRow = useMemo(() => <>
    {displayAlertRow
      && <TableRow className={classes.videoRow}>
        <TableCell className={classes.tableFirstColumn} />
        <AlertSegmentRow
          alertData={alertData}
          endTime={endTime}
          firstCellOffset={startTime.second}
          timeDisplay={timeDisplay}
        />
      </TableRow>
    }
  </>, [displayAlertRow, timeDisplay, startTime, endTime, alertData]);

  const featureRows = useMemo(() => <>
    {rowRender.map((videoFeature, index) => (
      <TableRow key={index} className={classes.videoRow}>
        <TableCell className={classes.tableFirstColumn}>
          {t(`common:features.camera-type.${videoFeature.featureDisplay}`)}
        </TableCell>
        {videoFeature.renderRow}
      </TableRow>
    ))
    }
  </>, [rowRender]);

  useEffect(() => {
    setRowRender(
      smallScreen
        ? [{
          featureDisplay: '',
          renderRow: <VideoSegmentRowMobile
            bannerOpen={bannerOpen}
            data={videoData.map(({ data }) => data)}
            dateRange={dateRange}
            disableBack={disableBack}
            disableForward={disableForward}
            handleBack={handleBack}
            handleFocus={handleFocus}
            handleForward={handleForward}
            modalOpen={handleModalOpen}
            refresh={refresh}
            thingId={thing._id}
            timeDisplay={timeDisplay}
          />,
        }]
        : videoData.map((promiseItem, index) => ({
          featureDisplay: promiseItem.feature,
          renderRow: <VideoSegmentRow
            key={index}
            bannerOpen={bannerOpen}
            data={promiseItem.data}
            endTime={endTime}
            handleFocus={handleFocus}
            modalOpen={handleModalOpen}
            refresh={refresh}
            startTime={startTime}
            thingId={thing._id}
            timeDisplay={timeDisplay}
            updateFinishDate={updateFinishDate}
          />,
        })
        )
    );
  }, [dateRange, filters, finishDate, smallScreen]);

  useEffect(() => {
    if (!focusedItem) return;
    setEnableTooltip(false);
    const timeout = setTimeout(() => {
      handleLineOffset(focusedItem.getBoundingClientRect().x);
    }, 50);

    return () => clearTimeout(timeout);
  }, [focusedItem, scrollLeft]);

  if (smallScreen) {
    return <>
      <LayoutMobile
        features={rowRender}
        handleGridLoaded={handleGridLoaded}
      />
      <VideoModal
        fileName={modalFileName}
        handleClose={handleModalClose}
        open={modalOpen}
        url={modalURL}
      />
    </>;
  }

  return <>
    <LayoutDesktop
      alertRow={alertRow}
      areaRef={areaRef}
      enableTooltip={enableTooltip}
      featureRows={featureRows}
      features={rowRender}
      finishDate={finishDate ? getFormattedTimeWithSeconds(DateTime.fromJSDate(finishDate)).replace(' ', '-') : 'no-time'}
      handleGridLoaded={handleGridLoaded}
      handleLineOffset={handleLineOffset}
      handleMouseLeave={handleMouseLeave}
      handleScroll={handleScroll}
      lineOffset={lineOffset}
      scrollOptions={scrollOptions}
      tableContainerRef={tableContainerRef}
      tableHeadRow={tableHeadRow}
      tooltipTime={tooltipTime}
    />
    <VideoModal
      fileName={modalFileName}
      handleClose={handleModalClose}
      open={modalOpen}
      url={modalURL}
    />
  </>;
};
