/* eslint-disable react-hooks/exhaustive-deps */
import { Time } from '@eagle/common';
import { SegmentData } from '@eagle/core-data-types';
import { VideoSegmentStatus } from '@eagle/data-function-types';
import { ErrorMessage, getFormattedTime, MiddleSpinner, Undefinable, usePromise } from '@eagle/react-common';
import { Grid, TableCell, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { max } from 'lodash';
import { DateTime } from 'luxon';
import { FC, FocusEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import VideoSegmentCell from './video-segment-cell';
import { CELL_WIDTH, tableStyles } from './video-segment.styles';
import { TimeDisplay, VideoCell, VideoCellType } from './video-segment.types';

interface Props {
  bannerOpen: (data: string) => void;
  data: Promise<SegmentData[]>;
  endTime: DateTime;
  handleFocus: (event: FocusEvent) => void;
  modalOpen: (url?: string, timeStart?: Date, feature?: string) => void;
  startTime: DateTime;
  thingId: string;
  timeDisplay: TimeDisplay[];
  updateFinishDate: (date?: Date) => void;
  refresh: boolean;
}

export const DEFAULT_PIXEL_LENGTH = 15;
const SHORT_TIME_MS = 15000;

export const VideoSegmentRow: FC<Props> = ({ bannerOpen, data, endTime, handleFocus, modalOpen, startTime, thingId, timeDisplay, updateFinishDate, refresh }) => {
  const [dataValue, dataError, dataState] = usePromise(() => data, [timeDisplay, refresh, data]);
  const { t } = useTranslation(['common', 'track']);
  const { classes } = tableStyles();

  const normalCells: Record<string, VideoCell[]> = {};
  const alertCells: Record<string, VideoCell[]> = {};
  let shortTime: Undefinable<DateTime>;

  const renderVideoData = (data: VideoCell[]): JSX.Element => {
    if (!data) return <Box className={classes.emptyCell} />;

    const subSectionData = data.reduce<Record<string, VideoCell[]>>((accumulator, current) => {
      accumulator[`subSection${current.subSection}`] = accumulator[`subSection${current.subSection}`] || [];
      accumulator[`subSection${current.subSection}`].push(current);
      return accumulator;
    }, {});

    return (
      <Box sx={{ flexWrap: 'nowrap', display: 'flex' }}>
        {
          Object.values(subSectionData).map((cell, cellIndex, cells) => {
            const { offset, subSection, timeStart } = cell[0];
            const pixelLength = cell[0].pixelLength ?? DEFAULT_PIXEL_LENGTH;
            const totalPixelLength = cells.slice(0, cellIndex).reduce((accumulator, current) => (current[0].pixelLength ?? DEFAULT_PIXEL_LENGTH) + accumulator, 0);
            const diffFromStartTime = DateTime.fromJSDate(timeStart).diff(startTime, 'seconds').seconds;
            const leftOffset = offset / Time.SECOND;
            const leftValue = cellIndex < 1 ? leftOffset : leftOffset - totalPixelLength;

            return (
              <Grid
                key={cellIndex}
                container
                direction={(subSection === -1) ? 'row' : 'column'}
                sx={{
                  flexWrap: 'nowrap',
                  left: diffFromStartTime <= 0 ? 0 : `${leftValue}px`,
                  position: 'relative',
                  width: (subSection === -1) ? `${Math.round(pixelLength)}px` : '15px',
                }}
              >
                {cell.map((shortCell, shortIndex) => (
                  <VideoSegmentCell
                    key={shortIndex}
                    bannerOpen={bannerOpen}
                    handleFocus={handleFocus}
                    modalOpen={modalOpen}
                    thingId={thingId}
                    videoCell={shortCell}
                  />
                ))}
              </Grid>
            );
          })
        }
      </Box>
    );
  };

  const dataUpdate = (data: Record<string, VideoCell[]>, timeSlot: string, output: VideoCell): void => {
    data[timeSlot] = (data[timeSlot] ?? []).concat(output);
  };

  const alertCheck = (currentStart: Date, previousEnd: Date): boolean => {
    return currentStart.getTime() < previousEnd.getTime();
  };

  const processShortVideos = (output: VideoCell, start: DateTime, videoIndex: number, section: string): void => {
    if (!shortTime) {
      shortTime = start.plus({ seconds: 15 });
      output.subSection = videoIndex;
      return dataUpdate(normalCells, section, output);
    }
    if (dataValue && videoIndex > 0 && alertCheck(dataValue[videoIndex].start, dataValue[videoIndex - 1].finish)) {
      return dataUpdate(alertCells, section, output);
    }
    if (shortTime.diff(start).milliseconds >= 0) {
      if (normalCells[section]?.length) {
        output.subSection = normalCells[section][normalCells[section].length - 1].subSection;
      }
      return dataUpdate(normalCells, section, output);
    }
    if (normalCells[section]) {
      output.subSection = videoIndex;
      shortTime = shortTime.plus({ seconds: 15 });
      return dataUpdate(normalCells, section, output);
    }
  };

  const processStartVideos = (output: VideoCell, start: DateTime, videoIndex: number, videoFinish: Date): void => {
    output.sectionStart = getFormattedTime(startTime);
    output.type = VideoCellType.BEFORE_START;
    output.timeLength = videoFinish.getTime() - startTime.toJSDate().getTime();
    output.pixelLength = output.timeLength / Time.SECOND;

    if (output.timeLength <= SHORT_TIME_MS) {
      shortTime = start.plus({ seconds: 15 });
      output.subSection = videoIndex;
      output.offset = 0;
      output.pixelLength = 15;
      dataUpdate(normalCells, getFormattedTime(startTime), output);
    }
    else if (dataValue && videoIndex > 0 && alertCheck(dataValue[videoIndex].start, dataValue[videoIndex - 1].finish)) {
      output.pixelLength = output.timeLength / Time.SECOND;
      dataUpdate(alertCells, getFormattedTime(startTime), output);
    }
    else {
      dataUpdate(normalCells, getFormattedTime(startTime), output);
    }
  };

  const processEndOfStackVideos = (output: VideoCell, section: string): void => {
    output.timeLength = output.timeLength + (output.offset - (normalCells[section] ? normalCells[section][0].offset : 0));
    output.offset = normalCells[section] ? normalCells[section][normalCells[section].length - 1].offset : 0;
    output.pixelLength = output.timeLength / Time.SECOND;
    output.subSection = normalCells[section] ? normalCells[section][normalCells[section].length - 1].subSection : -1;
    dataUpdate(normalCells, section, output);
  };

  const processAlertVideos = (output: VideoCell, section: string): void => {
    output.pixelLength = output.timeLength / Time.SECOND;
    dataUpdate(alertCells, section, output);
  };

  const processEndVideos = (output: VideoCell, start: DateTime, videoIndex: number, videoStart: Date): void => {
    output.sectionEnd = getFormattedTime(endTime);
    output.type = VideoCellType.AFTER_END;
    output.timeLength = endTime.toJSDate().getTime() - videoStart.getTime();
    output.pixelLength = output.timeLength / Time.SECOND;

    if (output.timeLength <= SHORT_TIME_MS) {
      shortTime = start.plus({ seconds: 15 });
      output.subSection = videoIndex;
      output.offset = 0;
      output.pixelLength = 15;
      dataUpdate(normalCells, getFormattedTime(start), output);
    }
    else if (dataValue && videoIndex > 0 && alertCheck(dataValue[videoIndex].start, dataValue[videoIndex - 1].finish)) {
      output.pixelLength = output.timeLength / Time.SECOND;
      dataUpdate(alertCells, getFormattedTime(start), output);
    }
    else {
      dataUpdate(normalCells, getFormattedTime(start), output);
    }
  };

  const processNormalVideos = (output: VideoCell, section: string): void => {
    output.pixelLength = output.timeLength / Time.SECOND;
    dataUpdate(normalCells, section, output);
  };

  useEffect(() => {
    if (dataState === 'resolved') {
      updateFinishDate(max(dataValue?.map((video) => {
        if (video.status !== VideoSegmentStatus.NOT_AVAILABLE && video.status !== VideoSegmentStatus.NO_LONGER_AVAILABLE) {
          return video.finish;
        }
      })));
    }
  }, [dataValue]);

  if (dataState === 'pending') {
    return (
      <TableCell colSpan={timeDisplay.length}>
        <MiddleSpinner size={20} sx={{ justifyContent: 'flex-start' }} />
      </TableCell>
    );
  }
  if (dataError) {
    return (
      <TableCell colSpan={timeDisplay.length}>
        <ErrorMessage error={dataError} />
      </TableCell>
    );
  }
  if (!dataValue || dataValue.length < 1) {
    return (
      <TableCell colSpan={timeDisplay.length}>
        <Typography variant='body2' fontStyle="italic" color="text.secondary">{t('common:common.hint.list.no-results')}</Typography>
      </TableCell>
    );
  }

  dataValue.forEach((video, videoIndex) => {
    const videoStart = video.start;
    const videoStartDateTime = DateTime.fromJSDate(videoStart);
    const videoFinish = video.finish;
    const videoFinishDateTime = DateTime.fromJSDate(videoFinish);
    const section = getFormattedTime(videoStartDateTime);
    const offset = videoStart.getMilliseconds() + (videoStart.getSeconds() * Time.SECOND);
    const msDiff = videoFinish.getTime() - videoStart.getTime();
    const isVideoStart15SecBeforeEnd = videoStartDateTime.plus({ seconds: 15 }).diff(endTime).milliseconds > 0;

    if (shortTime && shortTime.diff(videoStartDateTime).milliseconds < 0) {
      shortTime = undefined;
    }

    const output: VideoCell = {
      feature: video.feature,
      featureTypeId: video.featureTypeId,
      offset,
      pixelLength: DEFAULT_PIXEL_LENGTH,
      sectionStart: getFormattedTime(videoStartDateTime),
      sectionEnd: getFormattedTime(videoFinishDateTime),
      status: video.status,
      subSection: -1,
      thingId: video.thingId,
      timeStart: videoStart,
      timeLength: msDiff,
      timeFinish: videoFinish,
      type: VideoCellType.NORMAL,
      url: video.url,
    };

    if (!(output.timeLength <= SHORT_TIME_MS && isVideoStart15SecBeforeEnd)) {
      if ((startTime.toJSDate().getTime() > videoStart.getTime())) {
        processStartVideos(output, videoStartDateTime, videoIndex, videoFinish);
      }
      else if (videoFinish.getTime() > endTime.toJSDate().getTime()) {
        processEndVideos(output, videoStartDateTime, videoIndex, videoStart);
      }
      else if (output.timeLength <= SHORT_TIME_MS) {
        processShortVideos(output, videoStartDateTime, videoIndex, section);
      }
      else if (shortTime && shortTime.diff(videoStartDateTime).milliseconds > 0 && output.timeLength > SHORT_TIME_MS) {
        processEndOfStackVideos(output, section);
      }
      else if (videoIndex > 0 && alertCheck(dataValue[videoIndex].start, dataValue[videoIndex - 1].finish)) {
        processAlertVideos(output, section);
      }
      else {
        processNormalVideos(output, section);
      }
    }
  });

  return <>
    {timeDisplay.map((time, timeIndex) => {
      const cellWidth = timeIndex === 0 ? CELL_WIDTH - startTime.second : CELL_WIDTH;

      return (
        <TableCell
          key={timeIndex}
          className={classes.tableCell}
          sx={{ maxWidth: cellWidth, minWidth: cellWidth }}
        >
          <Box>
            {renderVideoData(normalCells[time.timeValue])}
          </Box>
          <Box>
            {renderVideoData(alertCells[time.timeValue])}
          </Box>
        </TableCell>
      );
    })}
  </>;
};
