import { AlertResponse } from '@eagle/core-data-types';
import { ErrorMessage, getFormattedTime, MiddleSpinner, usePromise } from '@eagle/react-common';
import { TableCell, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { DateTime } from 'luxon';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertSegmentCell } from './alert-segment-cell';
import { CELL_WIDTH, tableStyles } from './video-segment.styles';
import { TimeDisplay } from './video-segment.types';

const ALERT_ICON_WIDTH = 24;
const HIDE_ALERTS = 15;

interface Props {
  alertData: Promise<AlertResponse[]>;
  displayAlerts?: boolean;
  endTime: DateTime;
  firstCellOffset: number;
  timeDisplay: TimeDisplay[];
}

export const AlertSegmentRow: FC<Props> = ({ alertData, displayAlerts = true, endTime, firstCellOffset, timeDisplay }) => {
  const { t } = useTranslation(['common', 'track']);
  const { classes } = tableStyles();
  const [cells, setCells] = useState<Record<string, AlertResponse[]>>({});

  const [data, error, state] = usePromise(() => {
    return alertData.then((value) => {
      const alertCells = value.reduce<Record<string, AlertResponse[]>>((prev, currentAlert) => {
        const section = getFormattedTime(DateTime.fromJSDate(currentAlert.occurred));
        const secondsUntilEnd = endTime.toSeconds() - DateTime.fromJSDate(currentAlert.occurred).toSeconds();
        const row = prev[section] ?? [];
        if (secondsUntilEnd < HIDE_ALERTS) return prev;
        return {
          ...prev,
          [section]: [...row, currentAlert],
        };
      }, {});
      setCells(alertCells);
      return value;
    });
  }, [alertData, endTime]);

  if (!displayAlerts) return <></>;

  if (state === 'pending') {
    return (
      <TableCell colSpan={5} sx={{ border: 'none' }}>
        <MiddleSpinner size={20} sx={{ justifyContent: 'flex-start' }} />
      </TableCell>
    );
  }

  if (error) {
    return (
      <TableCell colSpan={5} sx={{ border: 'none' }}>
        <ErrorMessage error={error} />
      </TableCell>
    );
  }

  if (!data) {
    return (
      <TableCell colSpan={5} sx={{ border: 'none' }}>
        <Typography color="text.secondary" fontStyle="italic" variant="body2" >{t('common:common.hint.list.no-results')}</Typography>
      </TableCell>
    );
  }

  return <>
    {timeDisplay.map((time, timeIndex) => {
      const cell = cells[time.timeValue];
      const cellWidth = timeIndex === 0 ? CELL_WIDTH - firstCellOffset : CELL_WIDTH;

      return (
        <TableCell
          data-chromatic="ignore"
          key={timeIndex}
          className={classes.tableCell}
          sx={{ maxWidth: cellWidth, minWidth: cellWidth }}
        >
          {!cell && <Box sx={{ height: '30px' }} />}
          {cell?.map((alert, cellIndex) => {
            const paddingLeft = `${((alert.occurred.getSeconds() / 60) * CELL_WIDTH) - (ALERT_ICON_WIDTH / 2)}px`;
            return <Box key={cellIndex} sx={{ display: 'flex', flexWrap: 'nowrap', paddingLeft }}>
              <AlertSegmentCell alert={alert} />
            </Box>;
          })}
        </TableCell>
      );
    })}
  </>;
};
