/* eslint-disable react-hooks/rules-of-hooks */
import { DateFilterField } from '@eagle/react-common';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

export const dateOptions = (): DateFilterField[] => {
  const { t } = useTranslation(['common', 'track']);

  const dateOptions = [
    {
      id: '0',
      data: {
        endTime: (time: DateTime) => time,
        startTime: (time: DateTime) => time.minus({ hours: 8 }),
      },
      display: t('common:component.date-time-range.labels.last-hour', { count: 8 }),
    },
    {
      id: '1',
      data: {
        endTime: (time: DateTime) => time.plus({ day: 1 }).startOf('day'),
        startTime: (time: DateTime) => time.startOf('day'),
      },
      display: t('common:component.date-time-range.labels.today'),
    },
    {
      id: '2',
      data: {
        endTime: (time: DateTime) => time.startOf('day'),
        startTime: (time: DateTime) => time.minus({ days: 1 }).startOf('day'),
      },
      display: t('common:component.date-time-range.labels.yesterday'),
    },
  ];

  return dateOptions;
};
