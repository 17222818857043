import { VideoSegmentStatus } from '@eagle/data-function-types';
import { Display, Nullable } from '@eagle/react-common';

export interface TimeDisplay extends Display {
  tickHeight: number;
  timeValue: string;
}

export interface RequestAvailable {
  feature: string;
  finish: Date;
  start: Date;
  status?: VideoSegmentStatus;
}

export interface RequestUpload extends RequestAvailable {
  featureTypeId: string;
}

export interface VideoCell {
  feature: string;
  featureTypeId: string;
  offset: number;
  pixelLength?: number;
  sectionEnd: string;
  sectionStart: string;
  status: VideoSegmentStatus;
  subSection: number;
  thingId: string;
  timeFinish: Date;
  timeLength: number;
  timeStart: Date;
  type: string;
  url: Nullable<URL>;
}

export enum VideoCellType {
  AFTER_END = 'after-end',
  BEFORE_START = 'before-start',
  NORMAL = 'normal',
}
