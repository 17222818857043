/* eslint-disable react-hooks/exhaustive-deps */
import { Time } from '@eagle/common';
import { SegmentData } from '@eagle/core-data-types';
import { DateRange, ErrorMessage, LoadingButton, MiddleSpinner, ROUND, roundTimeToInterval, SEGMENT_MINUTES, Undefinable, usePromise } from '@eagle/react-common';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { DateTime } from 'luxon';
import { FC, FocusEvent, RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { scrollIntoView } from 'seamless-scroll-polyfill';
import { CameraDateOptions } from '../camera-content.types';
import { TimeFormatAccordion } from './video-content-util';
import VideoSegmentCell from './video-segment-cell';
import { TimeDisplay, VideoCell, VideoCellType } from './video-segment.types';

interface Props {
  bannerOpen: (data: string) => void;
  data: Promise<SegmentData[]>[];
  dateRange: CameraDateOptions;
  disableBack?: boolean;
  disableForward?: boolean;
  handleBack?: (date: DateRange, minDateTime?: DateTime, mobile?: boolean) => void;
  handleFocus: (event: FocusEvent) => void;
  handleForward?: (date: DateRange, mobileLogic?: boolean) => void;
  modalOpen: (url?: string, timeStart?: Date, feature?: string) => void;
  refresh: boolean;
  thingId: string;
  timeDisplay: TimeDisplay[];
}

interface VideoSegmentProps extends Omit<Props, 'data' | 'dateRange' | 'disableBack' | 'disableForward' | 'handleBack' | 'handleForward' | 'timeDisplay'> {
  dataValue?: SegmentData[][];
  earlierClicked: boolean;
  isLoading: boolean;
  laterClicked: boolean;
  resetClickedStates: () => void;
  videosRef: RefObject<HTMLDivElement>;
}

const VideoSegments: FC<VideoSegmentProps> = ({
  bannerOpen,
  dataValue,
  earlierClicked,
  handleFocus,
  isLoading,
  laterClicked,
  modalOpen,
  refresh,
  resetClickedStates,
  thingId,
  videosRef,
}) => {
  const { t } = useTranslation(['common', 'track']);
  const [videos, setVideos] = useState<Undefinable<VideoCell[][]>>();
  const [expandedAccordions, setExpandedAccordions] = useState<string[]>([]);

  const sorted = useMemo(() => {
    return dataValue
      ?.flat()
      .map(({ feature, featureTypeId, finish, start, status, thingId, url }) => ({
        feature,
        featureTypeId,
        offset: start.getMilliseconds() + (start.getSeconds() * Time.SECOND),
        sectionStart: roundTimeToInterval(start, ROUND.DOWN).toLocaleString(DateTime.TIME_WITH_SECONDS),
        sectionEnd: roundTimeToInterval(DateTime.fromJSDate(start).plus({ minutes: SEGMENT_MINUTES }).toJSDate(), ROUND.DOWN).toLocaleString(DateTime.TIME_WITH_SECONDS),
        status,
        subSection: -1,
        thingId,
        timeStart: start,
        timeLength: finish.getTime() - start.getTime(),
        timeFinish: finish,
        type: VideoCellType.NORMAL,
        url,
      }))
      .sort((a, b) => a.timeStart.getTime() - b.timeStart.getTime());
  }, [dataValue]);

  const timeFormattedVideos = useMemo(() => {
    let index = 0;
    return sorted && Object.values(
      sorted.reduce<Record<number, VideoCell[]>>((accumulator, current) => {
        const firstVideoBlock = accumulator[index]?.[0].sectionStart ?? current.sectionStart;
        const currentVideoBlock = current.sectionStart;

        const firstVideoStart = DateTime.fromJSDate(accumulator[index]?.[0].timeStart ?? current.timeStart);
        const currentVideoStart = DateTime.fromJSDate(current.timeStart);
        const { minutes: timeDifference } = currentVideoStart.diff(firstVideoStart, 'minutes');

        if (firstVideoBlock !== currentVideoBlock || timeDifference >= SEGMENT_MINUTES) index += 1;
        accumulator[index] = accumulator[index] || [];
        accumulator[index].push(current);
        return accumulator;
      }, {}),
    );
  }, [sorted]);

  const handleChange = (panel: string) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpandedAccordions(isExpanded ? [...expandedAccordions, panel] : [...expandedAccordions.filter((item) => item !== panel)]);
  };

  useEffect(() => {
    if (isLoading) return;
    resetClickedStates();

    if (!sorted) return;
    if (!videos?.length || timeFormattedVideos) setVideos(timeFormattedVideos);
    // timeFormattedVideos updates videos so adding them to the dependency causes maxDepth errors
  }, [isLoading, refresh]);

  useEffect(() => {
    if (!videosRef.current || isLoading || videos?.length === timeFormattedVideos?.length) return;
    const { current } = videosRef;
    if (earlierClicked) scrollIntoView(current, { behavior: 'smooth', block: 'start' });
    if (laterClicked) scrollIntoView(current, { behavior: 'smooth', block: 'end' });
  }, [videos, timeFormattedVideos, earlierClicked, isLoading, laterClicked, videosRef]);

  const getNextVideoCells = (i: number): Undefinable<VideoCell[]> => {
    if (!videos?.length || i >= videos.length - 1) return;
    return videos[i + 1];
  };

  const Videos = (): JSX.Element => <>
    {
      videos?.map((videoCells, i) => {
        if (!videoCells.length) return <></>;

        const sectionEnd = videoCells[videoCells.length - 1].sectionEnd;
        const sectionStart = videoCells[0].sectionStart;
        const timeStart = videoCells[0].timeStart;
        const nextVideoCells = getNextVideoCells(i);
        const timeFinish = videoCells[videoCells.length - 1].timeFinish;
        const nextTimeStart = nextVideoCells && nextVideoCells[0].timeStart;
        const showNoMedia = nextTimeStart && nextTimeStart.getTime() > timeFinish.getTime();

        return <>
          <Accordion
            key={i}
            disableGutters
            expanded={expandedAccordions.includes(`accordion-${i}`)}
            onChange={handleChange(`accordion-${i}`)}
            TransitionProps={{ mountOnEnter: true }}
            sx={{
              '&:before': { display: 'none' },
              '&': { boxShadow: 'none' },
              backgroundColor: 'transparent',
            }}
          >
            <AccordionSummary data-chromatic="ignore" expandIcon={<ExpandMoreIcon />} sx={{ '& > div': { justifyContent: 'space-between' }, flexDirection: 'row-reverse' }}>
              <Typography data-chromatic="ignore" variant="body2" sx={{ fontSize: '0.75rem' }}>
                <TimeFormatAccordion end={sectionEnd} start={sectionStart} timeStart={timeStart} />
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary', fontSize: '0.75rem' }}>{videoCells.length} {t('common:common.labels.videos')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {
                videoCells.map((shortCell, shortIndex) => (
                  <VideoSegmentCell
                    key={shortIndex}
                    bannerOpen={bannerOpen}
                    handleFocus={handleFocus}
                    mobile={true}
                    modalOpen={modalOpen}
                    thingId={thingId}
                    videoCell={shortCell}
                  />
                ))
              }
            </AccordionDetails>
          </Accordion>
          {showNoMedia
            && <Typography data-chromatic="ignore" variant="body2" sx={{ fontSize: '0.75rem', fontStyle: 'italic' }} align='center'>
              {t('track:page.camera-content.hint.no-media-between', {
                time1: DateTime.fromJSDate(timeFinish).toLocaleString(DateTime.TIME_WITH_SECONDS),
                time2: DateTime.fromJSDate(nextTimeStart).toLocaleString(DateTime.TIME_WITH_SECONDS),
              })}
            </Typography>
          }
        </>;
      })
    }
  </>;

  if (isLoading) return <MiddleSpinner />;

  if (!sorted?.length && !videos?.length) {
    return <Typography color="text.secondary" fontStyle="italic" variant="body2" sx={{ px: 3 }}>{t('common:common.hint.list.no-results')}</Typography>;
  }

  return (
    <Box sx={{ display: 'flex', flexWrap: 'nowrap' }}>
      <Grid
        alignItems="stretch"
        container
        flexDirection="column"
        sx={{
          flexWrap: 'nowrap',
          position: 'relative',
        }}
      >
        <Videos />
      </Grid>
    </Box>
  );
};

export const VideoSegmentRowMobile: FC<Props> = (props) => {
  const {
    data,
    dateRange,
    disableBack,
    disableForward,
    handleBack,
    handleForward,
    refresh,
    timeDisplay,
  } = props;
  const { t } = useTranslation(['track']);
  const [earlierClicked, setEarlierClicked] = useState(false);
  const [laterClicked, setLaterClicked] = useState(false);
  const [dataValue, dataError, dataState] = usePromise(() => Promise.all(data), [data, refresh, timeDisplay]);
  const videosRef = useRef<HTMLDivElement>(null);
  const isLoading = dataState === 'pending';
  const lessThanAddSegment = dateRange.endTime > DateTime.now().endOf('day').minus({ minutes: SEGMENT_MINUTES * 2 }) && dateRange.endTime.minute !== DateTime.now().endOf('day').minute;
  const isValidForward = dateRange.endTime.plus({ minutes: SEGMENT_MINUTES * 2 }) > DateTime.now().endOf('day') && !lessThanAddSegment;

  const resetClickedStates = (): void => {
    setLaterClicked(false);
    setEarlierClicked(false);
  };

  if (dataError) {
    return <ErrorMessage error={dataError} />;
  }

  return (
    <Grid ref={videosRef} sx={{ '&': { display: 'flex', flexDirection: 'column', height: '100%' } }}>
      <LoadingButton
        disabled={isLoading || disableBack}
        isLoading={earlierClicked && isLoading}
        onClick={() => {
          handleBack?.(dateRange, DateTime.now().minus({ years: 10 }), true);
          setEarlierClicked(true);
        }}
        sx={{ display: 'flex', fontSize: '0.813rem', height: '3rem', justifyContent: 'center', width: '100%' }}
        variant="text"
      >
        {t('track:page.camera-content.show-earlier.action')}
      </LoadingButton>
      <VideoSegments
        earlierClicked={earlierClicked}
        isLoading={isLoading}
        laterClicked={laterClicked}
        videosRef={videosRef}
        resetClickedStates={resetClickedStates}
        dataValue={dataValue}
        {...props} />
      <LoadingButton
        disabled={isLoading || disableForward || isValidForward}
        isLoading={laterClicked && isLoading}
        onClick={() => {
          handleForward?.(dateRange, true);
          setLaterClicked(true);
        }}
        sx={{ display: 'flex', fontSize: '0.813rem', height: '3rem', justifyContent: 'center', mt: 'auto', width: '100%' }}
        variant="text"
      >
        {t('track:page.camera-content.show-later.action')}
      </LoadingButton>
    </Grid>
  );
};
