import { Box, useTheme } from '@mui/material';
import { FC } from 'react';
import { TIMELINE_BAR_WIDTH } from './video-segment.styles';

interface Props {
  enableTooltip: boolean;
  left: number;
}

export const TimelineTooltipBar: FC<Props> = ({ enableTooltip, left }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        borderRightColor: enableTooltip ? theme.palette.common.black : 'transparent',
        borderRightStyle: 'solid',
        borderRightWidth: TIMELINE_BAR_WIDTH,
        height: `calc(100% - ${theme.videoSegment.scrollbarHeight})`,
        left,
        pointerEvents: 'none',
        position: 'sticky',
        zIndex: 1,
      }}
    />
  );
};
