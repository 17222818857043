// cspell:ignore popperjs
import { Tooltip, useTheme } from '@mui/material';
import { FC, PropsWithChildren, RefObject } from 'react';

interface Props extends PropsWithChildren {
  areaRef: RefObject<HTMLDivElement>;
  enableTooltip: boolean;
  lineOffset: number;
  tooltipTime: string;
}

export const TimelineTooltip: FC<Props> = ({
  areaRef,
  children,
  enableTooltip,
  lineOffset,
  tooltipTime,
}) => {
  const theme = useTheme();
  const black = theme.palette.common.black;

  return (
    <Tooltip
      arrow
      componentsProps={{
        arrow: { sx: { color: black } },
        tooltip: { sx: { backgroundColor: black } },
      }}
      placement="top"
      PopperProps={{
        anchorEl: {
          getBoundingClientRect: () => {
            return new DOMRect(
              lineOffset + (areaRef.current?.getBoundingClientRect().x ?? 0),
              areaRef.current?.getBoundingClientRect().y ?? 0,
              0,
              0,
            );
          },
        },
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -5],
            },
          },
        ],
        open: enableTooltip,
        style: { zIndex: 14 },
      }}
      title={tooltipTime}
    >
      <>{children}</>
    </Tooltip>
  );
};
