/* eslint-disable react-hooks/exhaustive-deps */
import { scrollbar } from '@eagle/react-common';
import { Box, Stack, Table, TableBody, TableContainer, TableHead, useTheme } from '@mui/material';
import { FC, ReactNode, RefObject, UIEvent, useEffect, useRef } from 'react';
import { scrollIntoView } from 'seamless-scroll-polyfill';
import { TimelineTooltip } from './timeline-tooltip';
import { TimelineTooltipBar } from './timeline-tooltip-bar';
import { tableStyles } from './video-segment.styles';

interface Props {
  alertRow: JSX.Element;
  areaRef: RefObject<HTMLDivElement>;
  enableTooltip: boolean;
  features: {
    featureDisplay: string;
    renderRow: JSX.Element;
  }[];
  featureRows: ReactNode;
  finishDate: string;
  handleGridLoaded: () => void;
  handleLineOffset: (value: number) => void;
  handleMouseLeave: () => void;
  handleScroll: (event: UIEvent) => void;
  lineOffset: number;
  scrollOptions?: {
    start: boolean;
    end: boolean;
  };
  tableContainerRef: RefObject<HTMLTableElement>;
  tableHeadRow: JSX.Element;
  tooltipTime: string;
}

export const LayoutDesktop: FC<Props> = ({
  alertRow,
  areaRef,
  enableTooltip,
  featureRows,
  features,
  finishDate,
  handleGridLoaded,
  handleLineOffset,
  handleMouseLeave,
  handleScroll,
  lineOffset,
  scrollOptions,
  tableContainerRef,
  tableHeadRow,
  tooltipTime,
}) => {
  const { classes } = tableStyles();
  const theme = useTheme();

  const startScroll = useRef<HTMLDivElement>(null);
  const endScroll = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!scrollOptions) return;
    scrollOptions.start && scrollIntoView(startScroll.current as Element, { behavior: 'smooth' });
    scrollOptions.end && scrollIntoView(endScroll.current as Element, { behavior: 'smooth' });
  }, [scrollOptions]);

  useEffect(() => {
    handleGridLoaded();
  }, [features]);

  useEffect(() => {
    const timeComp = document.getElementsByClassName(finishDate);
    if (timeComp.length === 0) return;
    scrollIntoView(timeComp[0], { behavior: 'smooth', inline: 'center' });
  }, [finishDate]);

  return (
    <Stack ref={areaRef} direction="row" flexGrow={1} overflow="auto">
      <TimelineTooltipBar enableTooltip={enableTooltip} left={lineOffset} />
      <TableContainer
        ref={tableContainerRef}
        onScroll={handleScroll}
        sx={{
          ...scrollbar,
          border: theme.videoSegment.tableBorder,
          display: 'flex',
          flexDirection: 'row',
          '&::-webkit-scrollbar': {
            height: theme.videoSegment.scrollbarHeight,
          },
        }}
      >
        <Box ref={startScroll} />
        <Table aria-label="video-table" className={classes.table}>
          <TableHead>
            {tableHeadRow}
          </TableHead>
          <TimelineTooltip
            areaRef={areaRef}
            enableTooltip={enableTooltip}
            lineOffset={lineOffset}
            tooltipTime={tooltipTime}
          >
            <TableBody
              data-testid='camera-content-timeline'
              onMouseLeave={handleMouseLeave}
              onMouseMove={(event) => handleLineOffset(event.clientX)}
              sx={{ flexGrow: 1 }}
            >
              {alertRow}
              {featureRows}
            </TableBody>
          </TimelineTooltip>
        </Table>
        <Box ref={endScroll} />
      </TableContainer>
    </Stack>
  );
};
